export default {
  data () {
    return {
      keywords: ['vehicles companies in rwanda', 'self drive rwanda', 'rwanda rent a car', 'car rental kigali', 'rwanda car rental', 'car hire rwanda', 'rent a car in rwanda', 'kigali car rental self drive', 'rent a car in kigali', 'rwanda car hire', 'location voiture kigali', 'kigali car rental with driver', 'car rental in kigali rwanda', 'hire a car in rwanda', 'kigali rwanda car hire', 'car rentals in kigali',
        'kigali rwanda car hire kigali', 'rent a car in kigali rwanda', 'renting a car in kigali', 'best car rental kigali', 'car for rent in rwanda', 'car rental companies in kigali', 'car rental companies in rwanda',
        'car rental in rwanda price', 'car rental kigali rwanda', 'car rental near me', 'car renting in kigali', 'cars for hire in kigali', 'cars for rent in rwanda', 'cheap car for rent in kigali', 'excesive car to rental in rwanda',
        'hire in rwanda', 'kigali car rental self drive price', 'kigali rent a car', 'kigali rwanda car hire kigali, rwanda', 'location voiture kigali rwanda']
    }
  }
}
