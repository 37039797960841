<template>
  <div class="main clearfix position-relative">
    <div class="main_1 clearfix position-absolute top-0 w-100">
      <HeaderItem />
    </div>
    <div class="main_2 clearfix">
      <section id="center" class="center_home">
        <div
          id="carouselExampleCaptions"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              class="active"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
              class=""
              aria-current="true"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="img/www-reallygreatsite-com.jpeg" class="d-block w-100 object-fit-scale" alt="..." />
              <div class="carousel-caption d-md-block">
                <h5 style="color: black">Plan your trip now</h5>
                <h1 class="font_50 mt-4" style="color: black">
                  Save <span class="col_oran">big</span> with our <br />
                  car rental
                </h1>
                <!-- <p class="mt-4 mb-4">
                  To contribute to positive change and achieve our
                  sustainability goals with many extraordinary
                </p> -->
                <br /><br />
                <h6 class="d-inline-block me-2 mb-0">
                  <a class="button" href="#"
                    >Book Ride <i class="fa fa-check-circle ms-1"></i>
                  </a>
                </h6>
                <h6 class="d-inline-block mb-0">
                  <!-- <a class="button_1" href="#"
                    >Learn More <i class="fa fa-check-circle ms-1"></i>
                  </a> -->
                </h6>
              </div>
            </div>
            <div class="carousel-item">
              <img src="img/1.jpeg" class="d-block w-100 object-fit-scale" alt="..." />
              <div class="carousel-caption d-md-block">
                <h5>Plan your trip now</h5>
                <h1 class="font_50 mt-4">
                  Best <span class="col_oran">Valued</span> deals you <br />
                  will ever find
                </h1>
                <!-- <p class="mt-4 mb-4">
                  To contribute to positive change and achieve our
                  sustainability goals with many extraordinary
                </p> -->
                <br /><br />
                <h6 class="d-inline-block me-2 mb-0">
                  <a class="button" href="#"
                    >Book Ride <i class="fa fa-check-circle ms-1"></i>
                  </a>
                </h6>
                <h6 class="d-inline-block mb-0">
                  <!-- <a class="button_1" href="#"
                    >Learn More <i class="fa fa-check-circle ms-1"></i>
                  </a> -->
                </h6>
              </div>
            </div>
            <div class="carousel-item">
              <img src="img/www-reallygreatsite-com-1.jpeg" class="d-block w-100 object-fit-scale" alt="..." />
              <div class="carousel-caption d-md-block">
                <h5 style="color: black">Plan your trip now</h5>
                <h1 class="font_50 mt-4" style="color: black">
                  We are <span class="col_oran">Local</span> Car Rental <br />
                  24/7 Support
                </h1>
                <!-- <p class="mt-4 mb-4">
                  To contribute to positive change and achieve our
                  sustainability goals with many extraordinary
                </p> -->
                <br /><br />
                <h6 class="d-inline-block me-2 mb-0">
                  <a class="button" href="#"
                    >Book Ride <i class="fa fa-check-circle ms-1"></i>
                  </a>
                </h6>
                <h6 class="d-inline-block mb-0">
                  <!-- <a class="button_1" href="#"
                    >Learn More <i class="fa fa-check-circle ms-1"></i>
                  </a> -->
                </h6>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </section>
    </div>
    <div class="main_3 position-absolute w-100 clearfix">
      <section id="booking">
        <div class="container-xl">
          <div class="booking_m clearfix bg-white">
            <div class="row booking_1">
              <div class="col-md-12">
                <h4 class="mb-0">Book a car</h4>
              </div>
            </div>
            <div class="row booking_2 mt-4">
              <div class="col-md-4 col-sm-6">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-car me-1 col_oran"></i> Entering Car Type you want
                  </h6>
                  <input type="text" v-model="carType" class="form-control" placeholder="Entering Car Type you want" />
                </div>
              </div>
              <div class="col-md-4 col-sm-6">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-map-marker me-1 col_oran"></i> Pick-up
                  </h6>
                  <input type="text" class="form-control" v-model="pickUp" placeholder="Tell us where you want to pick-up you">
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-map-marker me-1 col_oran"></i> Drop-of
                  </h6>
                  <input
                    type="text"
                    class="form-control" v-model="dropOf"
                    placeholder="Tell us where we Drop of you"
                  />
                </div>
              </div>
            </div>
            <div class="row booking_2 mt-4">
              <div class="col-md-4 col-sm-6">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-calendar me-1 col_oran"></i> Pick-up
                  </h6>
                  <div class="booking_2i1 row">
                    <div class="col-md-8">
                      <div class="booking_2i1l">
                        <input
                          class="form-control"
                          id="example-date"
                          type="date"
                          name="date"
                          v-model="pickUpdate"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 ps-0">
                      <div class="booking_2i1r">
                        <select class="form-select" id="example-select" v-model="pickUptime">
                          <option value="12:00 AM">12:00 AM</option>
                          <option value="12:30 AM">12:30 AM</option>
                          <option value="01:00 AM">01:00 AM</option>
                          <option value="01:30 AM">01:30 AM</option>
                          <option value="02:00 AM">02:00 AM</option>
                          <option value="02:30 AM">02:30 AM</option>
                          <option value="03:00 AM">03:00 AM</option>
                          <option value="03:30 AM">03:30 AM</option>
                          <option value="04:00 AM">04:00 AM</option>
                          <option value="04:30 AM">04:30 AM</option>
                          <option value="05:00 AM">05:00 AM</option>
                          <option value="05:30 AM">05:30 AM</option>
                          <option value="06:00 AM">06:00 AM</option>
                          <option value="06:30 AM">06:30 AM</option>
                          <option value="07:00 AM">07:00 AM</option>
                          <option value="07:30 AM">07:30 AM</option>
                          <option value="08:00 AM">08:00 AM</option>
                          <option value="08:30 AM">08:30 AM</option>
                          <option value="09:00 AM">09:00 AM</option>
                          <option value="09:30 AM">09:30 AM</option>
                          <option value="10:00 AM">10:00 AM</option>
                          <option value="10:30 AM">10:30 AM</option>
                          <option value="11:00 AM">11:00 AM</option>
                          <option value="11:30 AM">11:30 AM</option>
                          <option value="12:00 PM">12:00 PM</option>
                          <option value="12:30 PM">12:30 PM</option>
                          <option value="01:00 PM">01:00 PM</option>
                          <option value="01:30 PM">01:30 PM</option>
                          <option value="02:00 PM">02:00 PM</option>
                          <option value="02:30 PM">02:30 PM</option>
                          <option value="03:00 PM">03:00 PM</option>
                          <option value="03:30 PM">03:30 PM</option>
                          <option value="04:00 PM">04:00 PM</option>
                          <option value="04:30 PM">04:30 PM</option>
                          <option value="05:00 PM">05:00 PM</option>
                          <option value="05:30 PM">05:30 PM</option>
                          <option value="06:00 PM">06:00 PM</option>
                          <option value="06:30 PM">06:30 PM</option>
                          <option value="07:00 PM">07:00 PM</option>
                          <option value="07:30 PM">07:30 PM</option>
                          <option value="08:00 PM">08:00 PM</option>
                          <option value="08:30 PM">08:30 PM</option>
                          <option value="09:00 PM">09:00 PM</option>
                          <option value="09:30 PM">09:30 PM</option>
                          <option value="10:00 PM">10:30 PM</option>
                          <option value="10:30 PM">12:00 AM</option>
                          <option value="11:00 PM">11:00 PM</option>
                          <option value="11:30 PM">11:30 PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-calendar me-1 col_oran"></i> Drop-of
                  </h6>
                  <div class="booking_2i1 row">
                    <div class="col-md-8">
                      <div class="booking_2i1l">
                        <input
                          class="form-control"
                          id="example-date"
                          type="date"
                          name="date"
                          v-model="dropOfdate"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 ps-0">
                      <div class="booking_2i1r">
                        <select class="form-select" id="example-select" v-model="dropOftime">
                          <option value="12:00 AM">12:00 AM</option>
                          <option value="12:30 AM">12:30 AM</option>
                          <option value="01:00 AM">01:00 AM</option>
                          <option value="01:30 AM">01:30 AM</option>
                          <option value="02:00 AM">02:00 AM</option>
                          <option value="02:30 AM">02:30 AM</option>
                          <option value="03:00 AM">03:00 AM</option>
                          <option value="03:30 AM">03:30 AM</option>
                          <option value="04:00 AM">04:00 AM</option>
                          <option value="04:30 AM">04:30 AM</option>
                          <option value="05:00 AM">05:00 AM</option>
                          <option value="05:30 AM">05:30 AM</option>
                          <option value="06:00 AM">06:00 AM</option>
                          <option value="06:30 AM">06:30 AM</option>
                          <option value="07:00 AM">07:00 AM</option>
                          <option value="07:30 AM">07:30 AM</option>
                          <option value="08:00 AM">08:00 AM</option>
                          <option value="08:30 AM">08:30 AM</option>
                          <option value="09:00 AM">09:00 AM</option>
                          <option value="09:30 AM">09:30 AM</option>
                          <option value="10:00 AM">10:00 AM</option>
                          <option value="10:30 AM">10:30 AM</option>
                          <option value="11:00 AM">11:00 AM</option>
                          <option value="11:30 AM">11:30 AM</option>
                          <option value="12:00 PM">12:00 PM</option>
                          <option value="12:30 PM">12:30 PM</option>
                          <option value="01:00 PM">01:00 PM</option>
                          <option value="01:30 PM">01:30 PM</option>
                          <option value="02:00 PM">02:00 PM</option>
                          <option value="02:30 PM">02:30 PM</option>
                          <option value="03:00 PM">03:00 PM</option>
                          <option value="03:30 PM">03:30 PM</option>
                          <option value="04:00 PM">04:00 PM</option>
                          <option value="04:30 PM">04:30 PM</option>
                          <option value="05:00 PM">05:00 PM</option>
                          <option value="05:30 PM">05:30 PM</option>
                          <option value="06:00 PM">06:00 PM</option>
                          <option value="06:30 PM">06:30 PM</option>
                          <option value="07:00 PM">07:00 PM</option>
                          <option value="07:30 PM">07:30 PM</option>
                          <option value="08:00 PM">08:00 PM</option>
                          <option value="08:30 PM">08:30 PM</option>
                          <option value="09:00 PM">09:00 PM</option>
                          <option value="09:30 PM">09:30 PM</option>
                          <option value="10:00 PM">10:30 PM</option>
                          <option value="10:30 PM">12:00 AM</option>
                          <option value="11:00 PM">11:00 PM</option>
                          <option value="11:30 PM">11:30 PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-search me-1 col_oran"></i> Find
                  </h6>
                  <h6 class="text-center mb-0">
                    <a class="button pt-3 pb-3 d-block" href="#" @click="searchBookingCar();">Book a Car</a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <section id="trip" class="p_3">
    <div class="container-xl">
      <div class="row trip_1 text-center mb-4">
        <div class="col-md-12">
          <h5 class="col_oran">Tips of Booking a Car</h5>
          <h1 class="mb-0">Quick & easy car rental</h1>
        </div>
      </div>
      <div class="row trip_2">
        <div class="col-md-4">
          <div class="trip_2i text-center">
            <span class="d-inline-block fs-1"
              ><a class="col_oran"><i class="fa fa-car"></i></a
            ></span>
            <h3 class="mt-3"><a>Select Car</a></h3>
            <p class="mb-0 mt-3">
              <!-- (+250) 788 539 359 -->
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="trip_2i text-center">
            <span class="d-inline-block fs-1"
              ><a class="col_oran"><i class="fa fa-user-plus"></i></a
            ></span>
            <h3 class="mt-3"><a>Contact Operator</a></h3>
            <p class="mb-0 mt-3">(+250) 788 539 359</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="trip_2i text-center">
            <span class="d-inline-block fs-1"
              ><a class="col_oran"><i class="fa fa-car"></i></a
            ></span>
            <h3 class="mt-3"><a>Let's Drive</a></h3>
            <p class="mb-0 mt-3">
              <!-- To contribute to positive change and achieve our sustainability
              goals with many extraordinary -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="model_pg" class="p_3">
    <div class="container-xl">
      <div class="row model_pg1">
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a
                    ><img
                      src="img/27d309b3-2bf2-49bf-84af-397d26e422ca.jpeg"
                      class="w-100"
                      alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>Toyota landcruiser v8</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 7 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <div class="col-md-12">
                      <h6 class="mb-0">
                        <a class="button" href="#" @click="bookCar()"
                          >Book Car <i class="fa fa-check-circle ms-1"></i>
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a
                    ><img
                      src="img/02f18275-7609-4f7a-932c-3bf0b5c6e999.jpeg"
                      class="w-100"
                      alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>RAVA4 2016</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 5 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <div class="col-md-12">
                      <h6 class="mb-0">
                        <a class="button" href="#" @click="bookCar()"
                          >Book Car <i class="fa fa-check-circle ms-1"></i>
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a
                    ><img
                      src="img/4b57cb87-83f9-44fb-b384-5541f8150b3f.jpeg"
                      class="w-100"
                      alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>RAVA4 2017</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 5 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <div class="col-md-12">
                      <h6 class="mb-0">
                        <a class="button" href="#" @click="bookCar()"
                          >Book Car <i class="fa fa-check-circle ms-1"></i>
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a
                    ><img src="img/car1.jpeg" class="w-100" alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>Land Cruiser PRADO TXL</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 7 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <div class="col-md-12">
                      <h6 class="mb-0">
                        <a class="button" href="#" @click="bookCar()"
                          >Book Car <i class="fa fa-check-circle ms-1"></i>
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a
                    ><img src="img/car3prado.jpeg" class="w-100" alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>LAND CRUISER V8 WHITE</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 7 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <div class="col-md-12">
                      <h6 class="mb-0">
                        <a class="button" href="#" @click="bookCar()"
                          >Book Car <i class="fa fa-check-circle ms-1"></i>
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a
                    ><img src="img/car4.jpeg" class="w-100" alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>RAVA4</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 5 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <div class="col-md-12">
                      <h6 class="mb-0">
                        <a class="button" href="#" @click="bookCar()"
                          >Book Car <i class="fa fa-check-circle ms-1"></i>
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row model_pg1 mt-4">
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a
                    ><img src="img/car5.jpeg" class="w-100" alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>RAVA 4 2021</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 5 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <div class="col-md-12">
                      <h6 class="mb-0">
                        <a class="button" href="#" @click="bookCar()"
                          >Book Car <i class="fa fa-check-circle ms-1"></i>
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a
                    ><img src="img/car2prado.jpeg" class="w-100" alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>LANDCRUISER PRADO TXL</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 7 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <div class="col-md-12">
                      <h6 class="mb-0">
                        <a class="button" href="#" @click="bookCar()"
                          >Book Car <i class="fa fa-check-circle ms-1"></i>
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a
                    ><img
                      src="img/88c39049-47ac-40c8-8cc9-a06c72a65b1b.jpeg"
                      class="w-100"
                      alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>RAVA4 2021</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 7 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <div class="col-md-12">
                      <h6 class="mb-0">
                        <a class="button" href="#" @click="bookCar()"
                          >Book Car <i class="fa fa-check-circle ms-1"></i>
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="about_h" class="p_3 bg_light">
    <div class="container-xl">
      <div class="row about_h1">
        <div class="col-md-5">
          <div class="about_h1l clearfix position-relative">
            <div class="clearfix about_h1l1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a
                    ><img
                      src="img/02f18275-7609-4f7a-932c-3bf0b5c6e999.jpeg"
                      class="w-100"
                      alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <!-- <div class="clearfix about_h1l2 position-absolute">
              <span class="d-inline-block bg_oran text-center fs-3"
                ><a class="text-white d-block" href="#"
                  ><i class="fa fa-play"></i></a
              ></span>
            </div> -->
          </div>
        </div>
        <div class="col-md-7">
          <div class="about_h1r">
            <h5 class="col_oran">About Kigali Rwanda Car Hire Company</h5>
            <p class="mt-3">
              Our mission is to provide top-notch car rental services that
              empower our customers to explore the vibrant city of Kigali and
              beyond with confidence. We strive to deliver unmatched
              convenience, excellent customer service, and a fleet of
              well-maintained vehicles to meet your every travel requirement.
            </p>
            <hr />
            <div class="about_h1ri row">
              <div class="col-md-4 col-sm-4">
                <div class="about_h1ri1">
                  <span class="font_50 col_oran"
                    ><i class="fa fa-car"></i
                  ></span>
                  <h1>20</h1>
                  <h6 class="col_light fw-normal mb-0">Car Types</h6>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="about_h1ri1">
                  <span class="font_50 col_oran"
                    ><i class="fa fa-building"></i
                  ></span>
                  <h1>3</h1>
                  <h6 class="col_light fw-normal mb-0">Rental Outlets</h6>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="about_h1ri1">
                  <span class="font_50 col_oran"
                    ><i class="fa fa-shirtsinbulk"></i
                  ></span>
                  <h1>5</h1>
                  <h6 class="col_light fw-normal mb-0">Repair Shop</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="ride"></section>

  <section id="choose" class="p_3">
    <div class="container-xl">
      <div class="row choose_1">
        <div class="col-md-7">
          <div class="choose_1l">
            <h5 class="col_oran">Why Choose Us</h5>
            <h1>
              Best valued deals you <br />
              will ever find
            </h1>
            <p class="mt-3">
              Ready to embark on your journey with Discover Kigali Rwanda Car Hire?
              Contact our friendly team today to book your vehicle and
              experience the freedom of hassle-free travel.
            </p>
            <h6 class="mb-0 mt-4">
              <a class="button" href="#" @click="bookCar()"
                >Find Deals <i class="fa fa-check-circle ms-1"></i>
              </a>
            </h6>
          </div>
        </div>
        <div class="col-md-5">
          <div class="choose_1r">
            <div class="choose_1ri row">
              <div class="col-md-3">
                <div class="choose_1ril">
                  <span class="fs-1 d-inline-block text-center col_oran"
                    ><i class="fa fa-car"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-9">
                <div class="choose_1rir">
                  <h4>Cross Country Drive</h4>
                  <p class="mb-0 mt-3">
                    Your safety is our priority. Our vehicles undergo regular
                    maintenance, and we implement rigorous cleanliness and
                    sanitization practices.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div class="choose_1ri row mt-3">
              <div class="col-md-3">
                <div class="choose_1ril">
                  <span class="fs-1 d-inline-block text-center col_oran"
                    ><i class="fa fa-dollar"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-9">
                <div class="choose_1rir">
                  <h4>All Inclusive Pricing</h4>
                  <p class="mb-0 mt-3">
                    Our team is dedicated to exceeding customer expectations.
                    From reservation to drop-off, we are committed to making
                    your car rental experience smooth and enjoyable.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div class="choose_1ri row mt-3">
              <div class="col-md-3">
                <div class="choose_1ril">
                  <span class="fs-1 d-inline-block text-center col_oran"
                    ><i class="fa fa-rupee"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-9">
                <div class="choose_1rir">
                  <h4>No Hidden Charges</h4>
                  <p class="mb-0 mt-3">
                    We believe in making quality transportation accessible to
                    everyone. Enjoy competitive and transparent pricing with no
                    hidden fees.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="testim" class="p_3 bg_light">
    <div class="container-xl">
      <div class="row trip_1 text-center mb-4">
        <div class="col-md-12">
          <h5 class="col_oran">FAQ</h5>
          <h1>Frequently Asked Questions</h1>
        </div>
      </div>
      <div class="row faq_1">
        <div class="col-md-6">
          <div class="faq_1l">
            <div class="grid clearfix">
              <figure class="effect-jazz mb-0">
                <a
                  ><img src="img/faq.jpeg" class="w-100" alt="abc"
                /></a>
              </figure>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="faq_1r">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button mt-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What is special about comparing rental car deals?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Special deals with flexible cancellation and refund policies
                    provide peace of mind in case your plans change.
                    Understanding the terms and conditions related to
                    cancellations ensures you have flexibility when needed.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="heading3">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse3"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    How can i make Reservation ?
                  </button>
                </h2>
                <div
                  id="collapse3"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Reserving a vehicle is easy! You can make a reservation
                    online through our website or contact our customer service
                    to assist you with the booking process.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="heading4">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse4"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Do You Offer Airport Pickup and Drop-off Services?
                  </button>
                </h2>
                <div
                  id="collapse4"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Yes, we provide convenient airport pickup and drop-off
                    services to ensure a seamless transition to and from your
                    destination. Please provide your flight details during the
                    reservation process.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="heading5">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse5"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    What Documents Do I Need for Rental?
                  </button>
                </h2>
                <div
                  id="collapse5"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    To rent a car, you'll typically need a valid driver's
                    license, passport, and a credit card. Additional
                    requirements may apply, so it's best to check our terms and
                    conditions or contact us for specific details.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading5">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse5"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    What Fuel Policy Do You Have?
                  </button>
                </h2>
                <div
                  id="collapse5"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Our standard fuel policy is to provide the vehicle with a fuel, and we expect it to be returned with a fuel the same as provided.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <FooterItem />
</template>
<script>
import HeaderItem from '../components/HeaderItem.vue'
import FooterItem from '../components/FooterItem.vue'
export default {
  components: {
    HeaderItem,
    FooterItem
  },
  data () {
    return {
      carType: '',
      pickUp: '',
      dropOf: '',
      pickUpdate: '',
      pickUptime: '',
      dropOfdate: '',
      dropOftime: ''
    }
  },
  methods: {
    searchBookingCar () {
      const TypeCar = this.carType
      const pickUp = this.pickUp
      const dropOf = this.dropOf
      const pickUpdate = this.pickUpdate
      const pickUptime = this.pickUptime
      const dropOfdate = this.dropOfdate
      const dropOftime = this.dropOftime
      const phoneNumber = '250788497607'
      const title = 'Request to Book a CAR TO kigalirwandacarhire.com \n ----------------------------------- \n'
      const message = title + ' CAR NAME: ' + TypeCar + '\n \n Pick Up:' + pickUp + '\n \n Drop Of: ' + dropOf + '\n \n Pick Up Date: ' + pickUpdate + '\n \n  Pick Up time: ' + pickUptime + '\n \n Drop of Date ' + dropOfdate + '\n \n Drop of Time: ' + dropOftime
      const encodedMessage = encodeURIComponent(message)
      const link = `https://wa.me/${phoneNumber}?text=${encodedMessage}`
      window.open(link, '_blank')
    },
    bookCar () {
      confirm('Are you sure you want to book this car now ?') ? this.confirmBooking() : this.cancelBooking()
    },
    cancelBooking () {
      alert('Booking Car not yet done, Pleases try again')
    },
    confirmBooking () {
      const title = 'Request to Book a CAR TO kigalirwandacarhire.com \n ----------------------------------- \n'
      const phoneNumber = '250788539359'
      const names = '.....................'
      const email = '.....................'
      const customerPhone = '..............'
      const bookDescription = '..................................'
      const message = title + ' CAR NAME: ' + this.carName + '\n Your Names' + names + '\n Your Email: ' + email + '\n Your Phone Number: ' + customerPhone + '\n  Special Request: ' + bookDescription
      const encodedMessage = encodeURIComponent(message)
      const link = `https://wa.me/${phoneNumber}?text=${encodedMessage}`
      window.open(link, '_blank')
    }
  }
}
</script>
